import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer, PropTypes as MobxPropTypes } from 'mobx-react'
import Form from 'react-formal'
import * as R from 'ramda'
import { noop } from '../../../lib/utils/common'
import { isPresent } from '../../../lib/utils/collection'
import { useCaptchaHandlers } from '../Form/Captcha'
import { contactAgentSchema } from '../../../schemas/contactAgent'
import { Captcha, ErrorMessage } from '../../Shared/Form'
import PolicyInfo from './PolicyInfo'
import DateRangeFormField from './DateRangeFormField'
import cn from 'classnames'

const ContactForm = ({
  handleSubmit,
  contactFormStore,
  contactCaptcha,
  messagePlaceholder,
  setFormRef,
  isFxbo
}) => {
  const nameField = useRef(null)
  const emailField = useRef(null)
  const agreeContactZillowField = useRef(null)
  const agreeContactAdvertiserField = useRef(null)
  const formFields = useRef([nameField, emailField])
  const { captchaRef, resetCaptcha } = useCaptchaHandlers()
  const contactBy = isFxbo ? 'the homeowner advertising the property' : 'real estate professionals at ' + contactFormStore.companyName

  const handleChangeEmail = e => {
    contactFormStore.validateDomain(e.target.value)
  }

  const handleAgreement = (e) => {
    if (e.target.name == 'agreeContactZillow') {
      contactFormStore.validateAgreeZillow(e.target.checked)
    } else {
      contactFormStore.validateAgreeAdvertiser(e.target.checked)
    }
    contactFormStore.validateAgreement(agreeContactZillowField.current.checked && agreeContactAdvertiserField.current.checked)
  }

  const focusFirstInvalidField = errors => {
    const fields = R.pluck('current', formFields.current)
    for (let field of fields) {
      if (field.name in errors) {
        field.focus()
        break
      }
    }
  }

  const handleFormSubmit = () => {
    handleSubmit()
      .then(() => {
        contactCaptcha.checkCaptchaActive()
      })
      .then(() => {
        contactCaptcha.resetRecaptchaResponse()
        resetCaptcha()

        const errors = contactFormStore.formErrors
        if (isPresent(errors)) {
          focusFirstInvalidField(errors)
        }
      })
  }

  const handleFormChange = (value, [name]) => {
    contactFormStore.setField(name, value[name])
  }

  const handleInvalidSubmit = errors => {
    contactFormStore.setErrors(errors)
    focusFirstInvalidField(errors)
  }

  const isInvalid = name => contactFormStore.isFieldInvalid(name)

  useEffect(() => {
    contactCaptcha.checkCaptchaActive()

    global.requestAnimationFrame(() => {
      nameField.current.focus()
    })

    return () => {
      contactFormStore.setErrors({})
    }
  }, [])

  return (
    <Form
      ref={setFormRef}
      className='Form'
      onSubmit={handleFormSubmit}
      schema={contactAgentSchema}
      value={contactFormStore.toForm}
      onChange={handleFormChange}
      errors={contactFormStore.formErrors}
      onError={noop}
      onInvalidSubmit={handleInvalidSubmit}
    >
      <div role='alert' aria-live='assertive'>
        <Form.Message for={['error', 'base']}>
          {errors => <p className='ErrorText ErrorText--marginBottom10'>{errors}</p>}
        </Form.Message>
      </div>

      <p
        className='ErrorText ErrorText--warning ErrorText--marginTop0 ErrorText--marginBottom10'
        role='alert'
        aria-live='assertive'
      >
        {contactFormStore.domainError}
      </p>

      <div className='Form-row'>
        <label className='Label' htmlFor='name'>Name</label>
        <Form.Field
          type='text'
          name='name'
          id='name'
          errorClass='isInvalid'
          className='TextField'
          ref={nameField}
          aria-describedby='nameError'
          aria-errormessage={isInvalid('name') ? 'nameError' : null}
          aria-invalid={isInvalid('name') || null}
          autoComplete='name'
        />
        <Form.Message id='nameError' for='name'>
          {errors => (
            <ErrorMessage errorClassName='ErrorText' containerClassName='Form-row--noSpace'>
              {errors}
            </ErrorMessage>
          )}
        </Form.Message>
      </div>

      <div className='Form-row Form-row--columns'>
        <div className='Form-column Form-column--smallSpace'>
          <label className='Label' htmlFor='email'>Email</label>
          <Form.Field
            type='email'
            name='email'
            id='email'
            errorClass='isInvalid'
            className='TextField'
            ref={emailField}
            onChange={handleChangeEmail}
            aria-errormessage={isInvalid('email') ? 'emailError' : null}
            aria-invalid={isInvalid('email') || null}
            autoComplete='email'
          />
          <Form.Message id='emailError' for='email'>
            {errors => (
              <ErrorMessage errorClassName='ErrorText' containerClassName='Form-row--noSpace'>
                {errors}
              </ErrorMessage>
            )}
          </Form.Message>
        </div>

        <div className='Form-column Form-column--smallSpace'>
          <label className='Label' htmlFor='phone'>Phone (optional)</label>
          <Form.Field
            type='tel'
            name='phone'
            id='phone'
            className='TextField'
            autoComplete='tel'
          />
        </div>
      </div>

      <div className='Form-row'>
        <label className='Label' htmlFor='message'>Message (optional)</label>
        <Form.Field
          as='textarea'
          name='message'
          id='message'
          className='Textarea Textarea--contactFrom'
          placeholder={messagePlaceholder}
        />
      </div>

      {contactFormStore.listingClass === 'Rental' && (
        <div className='Form-row'>
          <DateRangeFormField contactFormStore={contactFormStore} />
        </div>
      )}

      <div className='Form-row Form-row--borderTop Form-row--paddingTop20px'>
        <label className='Label--terms'>
          Terms and Conditions <span className="Text Text--modalBottom">(required)</span>
        </label> 
      </div>

      <div className='Form-row'>
        <div className={cn('Checkbox', {'isError': contactFormStore.agreeZillowError})}>
          <label className='Checkbox-label' htmlFor='agree-contact-zillow'>
            <Form.Field
              id='agree-contact-zillow'
              type='checkbox'
              name='agreeContactZillow'
              errorClass='isInvalid'
              className='Checkbox-input'
              onChange={handleAgreement}
              ref={agreeContactZillowField}
              />
            <span className='Checkbox-icon'/>
            I agree to be contacted by Zillow Inc. (including Out East)
          </label>
        </div>
        <Form.Message className='ErrorText' for='agreeContactZillow'/>
      </div>

      <div className='Form-row'>
        <div className={cn('Checkbox', {'isError': contactFormStore.agreeAdvertiserError})}>
          <label className='Checkbox-label' htmlFor='agree-contact-advertiser'>
            <Form.Field
              id='agree-contact-advertiser'
              type='checkbox'
              name='agreeContactAdvertiser'
              errorClass='isInvalid'
              className='Checkbox-input'
              onChange={handleAgreement}
              ref={agreeContactAdvertiserField}
            />
            <span className='Checkbox-icon'/>
            I agree to be contacted by {contactBy}
          </label>
        </div>
        <Form.Message className='ErrorText' for='agreeContactAdvertiser'/>
      </div>

      <p
        className='ErrorText ErrorText--marginTop0 ErrorText--marginBottom20'
        role='alert'
        aria-live='assertive'
      >
        {contactFormStore.agreementError}
      </p>

      <Captcha
        isCaptchaActive={contactCaptcha.isCaptchaActive}
        onChange={contactCaptcha.updateRecaptchaResponse}
        setCaptchaRef={captchaRef}
      />
      <div className='Form-row'>
        <PolicyInfo isFxbo={isFxbo} />
      </div>
    </Form>
  )
}

ContactForm.propTypes = {
  contactFormStore: PropTypes.shape({
    setField: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired,
    toForm: MobxPropTypes.objectOrObservableObject.isRequired,
    formErrors: MobxPropTypes.objectOrObservableObject.isRequired,
    listingClass: PropTypes.string,
    domainError: PropTypes.string,
    agreementError: PropTypes.string,
    isFieldInvalid: PropTypes.func.isRequired,
    validateDomain: PropTypes.func.isRequired,
    validateAgreement: PropTypes.func.isRequired
  }),
  contactCaptcha: PropTypes.shape({
    isCaptchaActive: PropTypes.bool.isRequired,
    updateRecaptchaResponse: PropTypes.func.isRequired,
    checkCaptchaActive: PropTypes.func.isRequired,
    resetRecaptchaResponse: PropTypes.func.isRequired
  }),
  messagePlaceholder: PropTypes.string,
  setFormRef: PropTypes.func.isRequired,
  isFxbo: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

ContactForm.defaultProps = {
  messagePlaceholder: 'I’m interested in getting more info about this listing. Please let me know, thanks!'
}

export default observer(ContactForm)
