import { observe } from 'mobx'
import { lens } from 'lorgnette'
import PhotoGalleryUIStateStore from '../../../shared_components/Shared/stores/PhotoGalleryUIStateStore'
import UIStateStore from './UIStateStore'
import { CONTACT_FORM_TYPE } from '../../../lib/utils/stores'
import { joinWithEndWord, formatPeriodNamesWithDuplicates } from '../../../lib/utils/string'
import HdpStore from './HdpStore'
import MapStore from '../../../shared_components/Shared/stores/MapStore'
import ContactAgentFormStore from './ContactAgentFormStore'
import ContactOwnerFormStore from './ContactOwnerFormStore'
import ShareListingFormStore from './ShareListingFormStore'
import PricePeriodStore from './PricePeriodStore'
import PriceHistoryStore from './PriceHistoryStore'
import FlashMessagesStore from '../../FlashMessages/FlashMessagesStore'
import { CaptchaStore, UserStore } from '../../Shared/stores'
import { SHARE_LISTING_CAPTCHA_KEY, CONTACT_CAPTCHA_KEY } from '../../../lib/config/recaptcha'

const listingLens = id => lens.firstOf(item => item.id === id)
const isByOwner = ({ id, items }) => listingLens(id).prop('byOwner').get(items).getOr(false)
const getListor = ({ id, items }) => listingLens(id).prop('listor').get(items).getOr({})
const getZillow3dTour = ({ id, items }) => listingLens(id).prop('zillow3dTour').get(items).getOr(null)

class RootStore {
  constructor(context) {
    this.uiStateStore = new UIStateStore(context)
    this.photoGalleryUIStateStore = new PhotoGalleryUIStateStore(context)
    this.mapStore = new MapStore(context)
    this.hdpStore = new HdpStore({ ...context, root: this })
    this.contactAgentFormStore = new ContactAgentFormStore({ ...context, root: this })
    this.contactOwnerFormStore = new ContactOwnerFormStore({ ...context, root: this })
    this.shareListingFormStore = new ShareListingFormStore(context)
    this.shareListingCaptchaStore = new CaptchaStore({ ...context, captchaKey: SHARE_LISTING_CAPTCHA_KEY })
    this.contactCaptchaStore = new CaptchaStore({ ...context, captchaKey: CONTACT_CAPTCHA_KEY })
    this.flashMessageStore = new FlashMessagesStore()
    this.userStore = new UserStore(context)
    this.pricePeriodStore = new PricePeriodStore()
    this.priceHistoryStore = new PriceHistoryStore(context)
    this.setObservers()

    this.isViewport = context.isViewport
  }

  reset() {
    this.uiStateStore.reset()
    this.photoGalleryUIStateStore.reset()
  }

  setInitialDataImageGallery = data => {
    this.hdpStore.setInitialDataImageGallery(data)
    this.photoGalleryUIStateStore.setInitialData({
      listingId: data.mapInfo && data.mapInfo.listingId,
      images: data.images,
      variant: 'gallery',
      previewVariant: 'thumb',
      videoTour: data.videoTour
    })
    this.mapStore.setInitialData({
      ...data,
      listingId: data.mapInfo && data.mapInfo.listingId
    })

    if (!this.isViewport('mobile')) {
      this.mapStore.toggleCloseMapButtonVisible(this.hdpStore.hasImages)
    }

    this.photoGalleryUIStateStore.changeContentType(this.hdpStore.hasImages ? '' : 'map')
  }

  setInitialDataOpenListings = data => {
    this.hdpStore.setInitialDataOpenListings(data)
  }

  setInitialDataListingTitle = data => {
    this.hdpStore.setInitialDataListingTitle(data)
  }

  setInitialDataListingBy = data => {
    this.hdpStore.setInitialDataListingBy(data)
  }

  setInitialDataOfficeAddress = data => {
    this.hdpStore.setInitialDataOfficeAddress(data)
  }

  setInitialDataListingDescription = data => {
    this.hdpStore.setInitialDataListingDescription(data)
  }

  setInitialDataListingDetails = data => {
    this.hdpStore.setInitialDataListingDetails(data)
  }

  setInitialDataSavedButton = data => {
    this.hdpStore.setInitialDataSavedButton(data)
  }

  setInitialData3DTourButton = data => {
    this.hdpStore.set3DTourURL(data)
  }

  setInitialDataVideoTourButton = data => {
    this.hdpStore.setVideoTourURL(data)
  }

  setInitialDataShareListing = data => {
    this.hdpStore.setInitialDataShareListing(data)
    this.shareListingFormStore.setInitialData(data)
  }

  setInitialDataContactAgent = data => {
    this.contactAgentFormStore.setInitialData(data)
  }

  setInitialDataContactOwner = data => {
    this.contactOwnerFormStore.setInitialData(data)
  }

  setInitialDataPricePeriod = data => {
    this.pricePeriodStore.setInitialData(data)
  }

  setInitialDataPriceHistories = data => {
    this.priceHistoryStore.setInitialData(data)
  }

  setInitialDataStatusInfo = data => {
    this.hdpStore.setInitialDataStatusInfo(data)
  }

  setContactType = value => {
    let contactType

    if (this.hdpStore.hasOpenListings) {
      const openListings = this.hdpStore.openListings
      const listor = getListor({ id: value, items: openListings })
      const isListingByOwner = isByOwner({ id: value, items: openListings })

      contactType = isListingByOwner ? CONTACT_FORM_TYPE.owner : CONTACT_FORM_TYPE.agent

      if (!isListingByOwner) {
        this.contactAgentFormStore.setAgentInfo(listor)
      }
    } else {
      contactType = CONTACT_FORM_TYPE[this.hdpStore.contactTo.type]
    }

    this.uiStateStore.setContactType(contactType)
  }

  setZillow3DTourURL = listingId => {
    if (!this.hdpStore.hasOpenListings) {
      return
    }
    const openListings = this.hdpStore.openListings
    const url = getZillow3dTour({id: listingId, items: openListings})
    this.hdpStore.set3DTourURL({zillow3dTour: url})
  }

  setObservers() {
    observe(this.hdpStore, 'hasImages', ({ newValue }) => {
      if (!this.isViewport('mobile')) {
        this.mapStore.toggleCloseMapButtonVisible(newValue)
        this.photoGalleryUIStateStore.changeContentType(newValue ? '' : 'map')
      }
    })

    observe(this.contactAgentFormStore, 'contactMessage', ({ newValue }) => {
      this.flashMessageStore.addFlashMessage(newValue)
    })

    observe(this.contactOwnerFormStore, 'contactMessage', ({ newValue }) => {
      this.flashMessageStore.addFlashMessage(newValue)
    })

    observe(this.hdpStore, 'listingId', ({ newValue }) => {
      this.setContactType(newValue)
      this.contactAgentFormStore.toggleListingId(newValue)
      this.shareListingFormStore.toggleListingId(newValue)
      this.setZillow3DTourURL(newValue)
      this.photoGalleryUIStateStore.reset()

      if (this.photoGalleryUIStateStore.setInitialSlide) {
        this.photoGalleryUIStateStore.setInitialSlide()
      }

      this.hdpStore.updateViewedListings()
    })

    observe(this.shareListingFormStore, 'shareListingMessage', ({ newValue }) => {
      if(newValue.type === 'success') {
        this.uiStateStore.toggleShareListingModal(false)
      }
      this.flashMessageStore.addFlashMessage(newValue)
    })

    observe(this.hdpStore, 'currentOpenListing', ({ newValue }) => {
      this.photoGalleryUIStateStore.updateInfo(newValue)
    })

    observe(this.pricePeriodStore, 'selectedPeriods', ({ newValue }) => {
      this.contactAgentFormStore.setPricePeriodText(joinWithEndWord(formatPeriodNamesWithDuplicates(newValue)))
    })

    observe(this.uiStateStore, 'isShareListingModalOpen', ({ newValue }) => {
      this.shareListingCaptchaStore.resetRecaptchaResponse()

      if (newValue) {
        this.shareListingFormStore.sendAnalyticsModalOpen()
      }
    })
  }
}

export default RootStore
