import React, { Fragment, useEffect } from 'react'
import { observer } from 'mobx-react'
import PricePeriodModal from './PricePeriodModal'
import { useStores } from '../../../shared_components/Hooks'
import { getDefautlAvailablePeriods } from './utils'
import { isPresent } from '../../../lib/utils/collection'
import { Info, CalendarUnavailable } from '../../../shared_components/Svg'

const PricePeriod = () => {
  const { store: { uiStateStore, pricePeriodStore } } = useStores()
  const { prices, currentPeriod } = pricePeriodStore

  const availablePeriods = getDefautlAvailablePeriods(prices, currentPeriod)

  const handleClick = () => {
    uiStateStore.togglePricePeriodModal(true)
  }

  useEffect(() => {
    pricePeriodStore.setSelectedPeriods(availablePeriods)
  }, [availablePeriods])

  return (
    <Fragment>
      <div key='PricePeriod' className='PricePeriod'>
        <ul className='PricePeriod-priceList'>
          {availablePeriods.map((period, index) => (
            <li key={index} className='PricePeriod-priceItem'>
              <span className='PricePeriod-priceItem--info'>
                <span>{period.name} {period.year}</span>
                {!!period.note && (
                  <Info
                    className='PricePeriod-priceItem--infoIcon'
                    tooltip={period.note}
                  />
                )}
              </span>
              <span>{period.price}</span>
            </li>
          ))}
        </ul>

        {isPresent(availablePeriods) ? (
          <div className='PricePeriod-action'>
            <button
              className='Button Button--viewAllAvailability'
              onClick={handleClick}
            >
              View all availability
            </button>
          </div>
        ) : (
          <div className='PricePeriod-message'>
            <CalendarUnavailable className='PricePeriod-message--messageIcon' />
            <p className='PricePeriod-message--messageText'>No longer available for rent</p>
          </div>
        )}
      </div>
      <PricePeriodModal key='PricePeriodModal' />
    </Fragment>
  )
}

export default observer(PricePeriod)
